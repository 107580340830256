<template>
  <div id="app">
    <landingPage />
  </div>
</template>

<script>
import landingPage from "@/components/LandingPage.vue";
export default {
  name: "App",
  components: { landingPage },
};
</script>

<style></style>
