<template>
<div :class="{ 'dark crt': goofyMode }">
	<div class="
				h-screen
				bg-gradient-to-b
				from-green-700
				to-green-800
				flex flex-col
				items-center
				justify-center 
				text-white
				font-RobotoMono
				dark:from-black dark:to-black dark:text-terminal
			" :class="{ 'font-Source': goofyMode }">


		<div class="
					absolute
					top-6
					right-11
					text-xl
					hover:text-opacity-40
					cursor-pointer
				">
			<!-- <div class="w-11 h-6 rounded-full relative bg-black flex items-center border-4 border-black" :class="{
				'bg-terminal border-terminal': goofyMode,
			}" @click='toggleGoofyMode'>
				<div class=" absolute h-4 w-4 bg-white rounded-full left-0 transition-all delay-0 ease-out duration-300" :class="{
					'leftOn bg-black': goofyMode,
				}
					">

				</div>
			</div> -->
			<div class="flex items-center">
				<div class="animate-wiggle" @click="toggleGoofyMode">
					✨
				</div>
				<div class="mx-2">
					|
				</div>
				<div class="uppercase" @click="toggleLang">
					<span v-if="lang == 'en'">FR</span>
					<span v-else>EN</span>
				</div>
			</div>





		</div>


		<div class="
					w-auto
					m-auto
					animate-fadeIn
					transform-all
					text-6.5xl
					font-semibold
					tracking-wide
					text-center
					leading-none
				">
			<div :class="{ hidden: goofyMode }">
				<div class="animate-fadeIn transform">GABRIEL</div>
				<div class="">JALBERT</div>
			</div>
			<div :class="{ hidden: !goofyMode }">
				<pre class="text-terminal text-mini sm:text-xxs font-black">
					{{ text }}
				</pre>
			</div>

			<div class="mt-6 font-normal">
				<div class="
							text-sm
							flex flex-col
							items-center
							justify-center
						">
					<div class="w-4/5">
						{{ copy.intro1[lang] }}
						<span class="">{{ age }}</span>
						{{ copy.intro2[lang] }}
					</div>
					<div></div>

					<div class="mt-4">
						{{ copy.technologies[lang] }}
					</div>

					<div class="mt-4">
						{{ copy.internship[lang] }}
					</div>

					<div class="w-64 flex justify-evenly mx-auto mt-4">
						<a :href="`CV - Gabriel Jalbert - ${lang}.pdf`" class="cursor-pointer hover:text-gray-300" download>
							<span class="font-Icons text-4xl">description</span>
						</a>
						<a href="mailto:gabrieljalbert@gjalbert.com" class="hover:text-gray-300">
							<span class="font-Icons text-4xl">mail</span>
						</a>
						<a href="https://www.linkedin.com/in/gabrieljalbert/" target="_blank" rel="noopener noreferrer" class="hover:text-gray-300">
							<span class="font-Icons text-4xl">language</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import dayjs from "dayjs";
export default {
	name: "LandingPage",
	data() {
		return {
			age: null,
			copy: {

				intro1: {
					fr: "Je suis un dévéloppeur web fullstack de ",
					en: "I am a ",
				},
				intro2: {
					fr: "ans.",
					en: "year old fullstack web developer.",
				},
				technologies: {
					fr: "Je travail avec Node.js, Vue.js, HTML/CSS/JS et d'autres technologies.",
					en: "I work with Node.js, Vue.js, HTML/CSS/JS and other technologies.",
				},
				internship: {
					fr: "Je suis présentement à la recherche d'un stage en développement pour l'été 2024 dans la région de Montréal ou à distance.",
					en: "I am currently looking for an internship for summer 2024 in the Montreal region or in a WFH capacity."
				}
			},
			lang: "en",
			goofyMode: false,
			text: `
      ::::::::      :::     :::::::::  :::::::::  ::::::::::: :::::::::: :::    
    :+:    :+:   :+: :+:   :+:    :+: :+:    :+:     :+:     :+:        :+:     
   +:+         +:+   +:+  +:+    +:+ +:+    +:+     +:+     +:+        +:+      
  :#:        +#++:++#++: +#++:++#+  +#++:++#:      +#+     +#++:++#   +#+       
 +#+   +#+# +#+     +#+ +#+    +#+ +#+    +#+     +#+     +#+        +#+        
#+#    #+# #+#     #+# #+#    #+# #+#    #+#     #+#     #+#        #+#         
########  ###     ### #########  ###    ### ########### ########## ##########   

      ::::::::::: :::     :::        :::::::::  :::::::::: ::::::::: ::::::::::: 
         :+:   :+: :+:   :+:        :+:    :+: :+:        :+:    :+:    :+:      
        +:+  +:+   +:+  +:+        +:+    +:+ +:+        +:+    +:+    +:+       
       +#+ +#++:++#++: +#+        +#++:++#+  +#++:++#   +#++:++#:     +#+        
      +#+ +#+     +#+ +#+        +#+    +#+ +#+        +#+    +#+    +#+         
#+#  #+# #+#     #+# #+#        #+#    #+# #+#        #+#    #+#    #+#          
#####  ###     ### ########## #########  ########## ###    ###    ###           
`
		};
	},
	methods: {
		toggleLang() {
			this.lang == "en" ? (this.lang = "fr") : (this.lang = "en");
		},
		toggleGoofyMode() {
			this.goofyMode = !this.goofyMode;
		},
	},
	created() {
		navigator.language
			? (this.lang = navigator.language.substring(0,2))
			: (this.lang = "en");

		if (this.lang != "en" || this.lang != "fr") this.lang = "en";
		this.lang = "fr";
		setInterval(() => {
			let time = dayjs().diff(dayjs(980794812000),"year",true);
			this.age = time.toString().substring(0,12);
		},50);
	},

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.transformPos {
	transition-property: left;
}
</style>
